<script>
import Base from '@backend/Base.vue';
import BOGen from '@helper/BOGen';
import draggable from 'vuedraggable'

export default {
    name: "BoAboutAchivement",
    extends: Base,
    components: {
        draggable,
    },
    data() {
        return {
            Name: "BoAboutAchivement",
            dragData: {},
            dragging: true,
            list: [],
            dragging: false
        }
    },
    mounted() {
        this.$set(this.$root, 'page', this)
        this.refreshData()
    },
    methods:{
        endDrag(){
            BOGen.apirest('/'+Page.Name, {data:this.data.data,type:'sort'}, (err,resp)=>{
            console.log(resp)
            }, "POST");
        }
    },
    watch: {
        'data'(v){
            console.log(v)
        },
        '$route.query'(v) {
            this.refreshData()
        },
    },
};
</script>
<template>
<div class="container-fluid">
    <PageTitle></PageTitle>

    <!-- ========================================================================
    LIST DATA
    ============================================================================= -->
    <div class="panel panel-flat" v-if="!$route.params.id">
        <div class="panel-body panel-filter">
            <VForm @resp="search">
            <div class="row">
                <div class="col-sm-3">
                    <h3 class="box-title">{{ObjectName}} List <span
                    v-if="data.total">({{(data.total||0).format()}})</span></h3>
                </div>
                <div class="col-sm-5"></div>
                <div class="col-sm-3">
                    <div class="has-feedback has-feedback-left">
                        <input type="search" v-model="filter.search" class="form-control" placeholder="Type and hit Enter">
                        <div class="form-control-feedback">
                            <i class="icon-search4 text-size-base text-muted"></i>
                        </div>
                    </div>
                </div>
                <div class="col-sm-1">
                    <router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
                </div>
            </div>
            </VForm>
        </div>
        <div class="table-responsive ajax-table">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>
                            <SortField name="maa_title"></SortField>
                        </th>
                        <th>{{fields.maa_color}}</th>
                        <th>{{fields.maa_image}}</th>
                        <th>{{fields.maa_is_active}}</th>
                        <th width="120px">Action</th>
                    </tr>
                </thead>
                <!-- <tbody> -->
                <draggable v-model="data.data" tag="tbody" @end="endDrag" style="cursor:pointer;">
                    <tr v-for="(v,k) in data.data" :key="k">
                        <td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
                        <td>{{v.maa_title}}</td>
                        <td>{{v.maa_color}}</td>
                        <td><ImagePreview :src="uploader(v.maa_image)"></ImagePreview></td>
                        <td>
                            <StatusLabel :status="v.status"></StatusLabel>
                        </td>
                        <td class="btn-action">
                            <router-link class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'">
                            <i class="icon-pencil"></i></router-link>
                            <a href="javascript:;" class="icon_action" @click="changeStatus(k)"
                            v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
                            <a href="javascript:;" class="icon_action" @click="deleteItem($event,k)"
                            v-tooltip="'Remove'"><i class="icon-trash"></i></a>
                        </td>
                    </tr>
                    <tr v-if="NotFound">
                        <td colspan="99">
                            <h3 class="tc">{{NotFound}}</h3>
                        </td>
                    </tr>
                    <tr v-if="data.data===false">
                        <td colspan="99">
                            <LoadingSpinner light></LoadingSpinner>
                        </td>
                    </tr>
                </draggable>
                <!-- </tbody> -->
            </table>
        </div>
        <div class="panel-body">
            <div class="pull-right">
                <Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
            </div>
        </div>
    </div>

    <!-- ========================================================================
    FORM CRUD
    ============================================================================= -->
    <div class="panel panel-flat" v-if="$route.params.id">
        <VForm @resp="submitForm" method="post">
            <div class="panel-heading">
                <h2 class="panel-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h2>
            </div>
            <div class="panel-body">
                <div class="info"></div>
                <div class="row">
                    <div class="col-sm-6">
                        <BoField name="maa_color">
                                <select2 name="maa_color" v-bind="validation('bu_status')" :options="colorType" v-model="row.maa_color" class="form-control">
                                <option value="">-- Select Color --</option>
                            </select2>
                        </BoField>
                        <BoField name="maa_title" v-model="row.maa_title" v-bind="validation('maa_title')"></BoField>
                        <BoField name="maa_desc">
                            <CKEditor name="maa_desc" v-model="row.maa_desc" v-bind="validation('maa_desc')">
                            </CKEditor>
                        </BoField>
                    </div>
                    <div class="col-sm-6">
                        <BoField name="maa_image" info="Rasio ukuran 1:1, max. 75x75px">
                            <Uploader name="maa_image" type="about_achievement" uploadType="galery" v-model="row.maa_image" v-bind="validation('maa_image')"></Uploader>
                        </BoField>
                        <BoField name="maa_is_active">
                            <div class="radio-group">
                                <radio name="maa_is_active" v-model="row.maa_is_active" option="Y"
                                :attr="validation('maa_is_active')">Active</radio>
                                <radio name="maa_is_active" v-model="row.maa_is_active" option="N">Inactive</radio>
                            </div>
                        </BoField>
                    </div>
                    <div class="col-xs-12">
                        <div class="text-right">
                            <button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form <i class="icon-arrow-right14 position-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </VForm>
    </div>
</div>
</template>